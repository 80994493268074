import { MiniControls } from '@canalplus/oneplayer-shared-components';
import '@canalplus/oneplayer-shared-components/dist/css/index.css'; // Global styles of oneplayer-shared-components
import { MaterialArrowBackSvg } from '@dce-front/dive';
import {
  Binder,
  KEY_BACK,
  KEY_UP,
  useActiveLayer,
  useKeyCatcher,
  useStore,
} from '@dce-front/one-navigation';
import classNames from 'classnames';
import type { JSX, RefObject } from 'react';
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import screenfull from 'screenfull';
import { useUnmount } from 'usehooks-ts';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import {
  LAYER_EMBEDDED_VIDEO_FULL_FRAME,
  LAYER_IMMERSIVE,
} from '../../../../helpers/oneNavigation/layers';
import { useTranslation } from '../../../../lang';
import {
  getFeatureToggleMinimalPlayerDebug,
  isIOsSelector,
} from '../../../../store/slices/application-selectors';
import { setAreAnimationsTemporarilyUnmuted } from '../../../../store/slices/user';
import ErrorTemplate from '../../../Error/ErrorTemplate';
import { resetValues, setPlayerInstance } from '../../context/actions';
import { useSetIsFullFrame } from '../../context/hooks/useSetIsFullFrame';
import { useVideoDispatch } from '../../context/hooks/useVideoDispatch';
import { useVideoState } from '../../context/hooks/useVideoState';
import { useGetPlayerCredentials } from '../../hooks/useGetPlayerCredentials';
import type { VideoContent } from '../../types';
import styles from './PlayerContainer.css';
import type {
  ErrorEventFromMinimalPlayer,
  UseGetPlayerInstanceParams,
} from './useGetPlayerInstance';
import { useGetPlayerInstance } from './useGetPlayerInstance';
import { useLoadOnePlayerMinimalScript } from './useLoadOnePlayerMinimalScript';

export enum ScaleUp {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

// Warning: to avoid rerendering side effects, do not update the props to accept objects
export type PlayerContainerProps = {
  /**
   * The video content
   */
  videoContent: VideoContent;
  /**
   * If true, displays player controls
   */
  hasControls?: boolean;
  /**
   * If true and `hasControls` enabled, displays exit button
   */
  hasExitButton?: boolean;
  /**
   * If true, display on TV the full frame indicator
   */
  hasFullFrameIndicator?: boolean;
  /**
   * If true, displays subtitles
   */
  hasSubtitles?: boolean;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * If the value is changing, the player will be updated with the new one
   */
  isMuted: boolean;
  /**
   * If true, player is paused
   */
  isPaused?: boolean;
  /**
   * Fullscreen target ref element
   */
  fullscreenRef?: RefObject<HTMLDivElement | null>;
  /**
   * If true, the video is ready to play (play and show controls)
   */
  isVideoReadyToPlay?: boolean;
  /**
   * If defined, main div is scaled up to hide the back bars according to the value
   */
  scaleUp?: ScaleUp;
} & Pick<
  UseGetPlayerInstanceParams,
  'onNaturalEnding' | 'onVideoLoaded' | 'onVideoPlaying'
>;

function PlayerContainer({
  videoContent,
  hasControls = false,
  hasExitButton = true,
  hasFullFrameIndicator = false,
  hasSubtitles = false,
  isLoop = false,
  isMuted,
  isPaused = false,
  isVideoReadyToPlay = false,
  fullscreenRef,
  onNaturalEnding,
  onVideoLoaded,
  onVideoPlaying,
  scaleUp,
}: PlayerContainerProps): JSX.Element {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isFullFrame, playerInstance } = useVideoState();
  const deferredIsMuted = useDeferredValue(isMuted);
  const videoDispatch = useVideoDispatch();
  const appDispatch = useAppDispatch();
  const store = useStore(); // OneNavigation store
  const setIsFullFrame = useSetIsFullFrame();
  const isIOS = useInvariantSelector(isIOsSelector);
  const featMinimalPlayerDebug = useInvariantSelector(
    getFeatureToggleMinimalPlayerDebug,
  );
  const [error, setError] = useState<ErrorEventFromMinimalPlayer | null>(null);

  // FullFrame and FullScreen management
  const isFullScreenAvailable =
    !isIOS && screenfull.isEnabled && !$_BUILD_RENDERMODE_CSR;
  const [isMaxFullFramePossible, setIsMaxFullFramePossible] = useState(
    isFullScreenAvailable ? screenfull.isFullscreen : isFullFrame,
  );

  // To get OnePlayer parameters
  const credentials = useGetPlayerCredentials();
  const isPassTokenValid =
    !!credentials.passToken && credentials.passToken.length > 0; // OnePlayer handle passToken renewal

  const { getPlayerInstance, playerContainerRef, subtitlesContainerRef } =
    useGetPlayerInstance({
      videoContent,
      hasSubtitles,
      isLoop,
      isMuted,
      onVideoLoaded,
      onVideoPlaying,
      onNaturalEnding,
      onError: (value) => {
        if (featMinimalPlayerDebug) {
          setError(value);
        }
      },
    });

  // OnePlayerMinimal instancing
  const { isLoaded: isOnePlayerMinimalScriptLoaded } =
    useLoadOnePlayerMinimalScript();

  const instantiateVideo = useCallback(() => {
    videoDispatch(setPlayerInstance(getPlayerInstance()));
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // wait until minimal player is loaded and check if the passtoken is valid
    if (isOnePlayerMinimalScriptLoaded && isPassTokenValid) {
      instantiateVideo();
    }
  }, [isOnePlayerMinimalScriptLoaded, isPassTokenValid, instantiateVideo]);

  const removePlayer = () => {
    if (!playerInstance) {
      console.warn(
        '[PlayerContainer]: memory leak ! Unable to destroy instance of onePlayerMinimal',
      );
    }

    console.info('[Video]: OnePlayerMinimal destroy instance');
    playerInstance?.destroy();
    videoDispatch(resetValues()); // To reset the context values
  };

  // Remove player on unmount
  useUnmount(() => {
    removePlayer();
  });

  // Handle closing full frame by escape (Fullscreen API manages itself already,
  // this is only for isFullFrame)
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (isFullScreenAvailable && screenfull.isFullscreen) {
          return;
        }
        setIsFullFrame(false);
      }
    },
    [setIsFullFrame, isFullScreenAvailable],
  );

  /**
   * Handle opening full frame and fullscreen depending on the isFullFrame state.
   *  - If not in FullFrame: open in FullFrame
   *  - If in FullFrame: open in FullScreen
   *  - If in FullScreen: exit FullScreen back to FullFrame
   */
  const handleOnFullFrame = useCallback(() => {
    if (isFullFrame) {
      if (isFullScreenAvailable) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
        } else {
          const target = fullscreenRef?.current || containerRef.current;
          if (target) {
            // ?. needed because if not iOS will throw an error because screenfull.request is undefined
            screenfull.request?.(target);
          }
        }
      } else {
        // When FullScreen is not available, we just exit the FullFrame
        setIsFullFrame(false);
      }
    } else {
      setIsFullFrame(true);
    }
  }, [isFullFrame, isFullScreenAvailable, fullscreenRef, setIsFullFrame]);

  const handleOnMute = useCallback(() => {
    appDispatch(setAreAnimationsTemporarilyUnmuted(false));
  }, [appDispatch]);

  const handleOnUnMute = useCallback(() => {
    appDispatch(setAreAnimationsTemporarilyUnmuted(true));
  }, [appDispatch]);

  // Handle clicking on close button
  const handleOnExit = useCallback(() => {
    if (isFullScreenAvailable && screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      setIsFullFrame(false);
    }
  }, [isFullScreenAvailable, setIsFullFrame]);

  const onScreenfullChange = useCallback(() => {
    setIsMaxFullFramePossible(screenfull.isFullscreen);
  }, [setIsMaxFullFramePossible]);

  /* Manages Fullscreen changes */
  useEffect(() => {
    if (!isFullScreenAvailable) {
      return;
    }
    screenfull.on('change', onScreenfullChange);
    return () => {
      screenfull.off('change', onScreenfullChange);
    };
  }, [isFullScreenAvailable, onScreenfullChange]);

  useEffect(() => {
    if (isFullFrame) {
      document.body.addEventListener('keydown', handleKeydown);
    } else {
      document.body.removeEventListener('keydown', handleKeydown);
    }

    return () => {
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown, isFullFrame]);

  // Handle Full Frame opening and closing by One Navigation
  useActiveLayer(LAYER_EMBEDDED_VIDEO_FULL_FRAME, isFullFrame);
  useKeyCatcher(
    KEY_BACK,
    () => {
      if (isFullFrame) {
        setIsFullFrame(false);
      }
    },
    LAYER_EMBEDDED_VIDEO_FULL_FRAME,
  );
  useKeyCatcher(
    KEY_UP,
    () => {
      // Open Full Frame only when the focus is on the first element of the active layer of OneNavigation...
      const firstInteractiveElement =
        store.layers[store.activeLayer]?.binders[0]?.getElements()[0];

      // ... and the video is ready to be played (player is ready and animations are done)
      if (
        hasFullFrameIndicator &&
        isVideoReadyToPlay &&
        !isFullFrame &&
        store.current === firstInteractiveElement
      ) {
        setIsFullFrame(true);
      }
    },
    LAYER_IMMERSIVE,
  );

  // Impact the player with the props isMuted only if the value is changing
  useEffect(() => {
    if (!playerInstance || deferredIsMuted === isMuted) {
      return;
    }
    if (isMuted) {
      console.info('[Video]: playerInstance mute');
      playerInstance.mute();
    } else {
      console.info('[Video]: playerInstance unMute');
      playerInstance.unMute();
    }
  }, [deferredIsMuted, isMuted, playerInstance]);

  // Impact the player with the props isPaused
  useEffect(() => {
    if (!playerInstance) {
      return;
    }
    if (isPaused) {
      console.info('[Video]: playerInstance pause');
      playerInstance.pause();
    } else {
      console.info('[Video]: playerInstance play');
      playerInstance.play();
    }
  }, [isPaused, playerInstance]);

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {
        [styles['container--fullscreen']!]: isFullFrame,
      })}
    >
      {hasFullFrameIndicator && isVideoReadyToPlay && !isFullFrame && (
        <div className={styles.indicator}>
          <MaterialArrowBackSvg />
          <span>{t('Video.fullscreen')}</span>
        </div>
      )}
      {hasSubtitles && <div ref={subtitlesContainerRef} />}
      <div
        className={classNames(styles.player, {
          [styles['player--scale-up-small']!]:
            scaleUp === ScaleUp.Small && !isFullFrame,
          [styles['player--scale-up-medium']!]:
            scaleUp === ScaleUp.Medium && !isFullFrame,
          [styles['player--scale-up-large']!]:
            scaleUp === ScaleUp.Large && !isFullFrame,
        })}
        ref={playerContainerRef}
      />
      {isVideoReadyToPlay && (isFullFrame || hasControls) && (
        <Binder enabled={isFullFrame} className={styles.controls}>
          <MiniControls
            forceFocusOnFullFrame
            isFullFrame={
              isFullScreenAvailable ? isMaxFullFramePossible : isFullFrame
            }
            isTv={$_BUILD_RENDERMODE_CSR}
            onExit={handleOnExit}
            onFullFrame={handleOnFullFrame}
            onMute={handleOnMute}
            onUnmute={handleOnUnMute}
            shouldShowExitButton={hasExitButton}
          />
        </Binder>
      )}
      {featMinimalPlayerDebug && error && (
        <ErrorTemplate
          className={styles['container__errorTemplate']}
          title="Error on Minimal Player"
          text={error?.detail?.message}
          error={
            error?.detail?.details?.message
              ? {
                  name: 'Error on Minimal Player',
                  message: error?.detail?.details.message,
                  stack: error?.detail?.details.code,
                }
              : undefined
          }
          isBackButtonHidden
        />
      )}
    </div>
  );
}

export default PlayerContainer;
